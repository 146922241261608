import { LS_WORKS_URL } from 'apps/lsworks/src/app/app.constants';

interface menuItem {
  label: string;
  redirect?: string;
  fragment?: string;
  subMenu?: menuItem[];
}

export const websiteMenuItems: menuItem[] = [
  {
    label: 'common.whats-my-neurotype',
    subMenu: [
      {
        label: 'menus.am-i-dislexic-sub-menu.what-is-dislexia',
        redirect: 'what-is-dyslexia',
      },
      {
        label: 'common.dyslexia-screening-tool',
        redirect: 'dyslexia-screening',
      },
      {
        label: 'common.what-is-adhd',
        redirect: 'adhd',
      },
      {
        label: 'common.what-is-autism',
        redirect: 'what-is-autism',
      },
      {
        label: 'common.cerebral-palsy',
        redirect: 'cerebral-palsy',
      },
    ],
  },
  {
    label: 'menus.what-is-neuralign',
    subMenu: [
      {
        label: 'common.about-us',
        redirect: 'about-us',
      },
      {
        label: 'menus.what-is-neuralign-sub-menu.how-does-it-work',
        redirect: 'how-it-works',
      },
      {
        label: 'menus.what-is-neuralign-sub-menu.for-kids',
        redirect: 'for-kids',
      },
      {
        label: 'menus.what-is-neuralign-sub-menu.for-adults',
        redirect: 'for-adults',
      },
      {
        label: 'menus.what-is-neuralign-sub-menu.for-educators',
        redirect: 'practitioners',
      },
      {
        label: 'menus.what-is-neuralign-sub-menu.demo',
        redirect: 'demo',
      },
    ],
  },
  {
    label: 'menus.evidence-basis',
    redirect: 'evidence',
  },
  {
    label: 'menus.testimonials',
    redirect: 'testimonials',
  },
  {
    label: 'common.foundation',
    redirect: 'foundation',
  },
  {
    label: 'menus.resource-library',
    redirect: 'news',
    subMenu: [
      {
        label: 'menus.news',
        redirect: 'news',
      },
      {
        label: 'menus.blog',
        redirect: 'blog',
      },
    ],
  },
  {
    label: 'common.faq',
    redirect: 'faq',
  },
  {
    label: 'common.log-in',
    redirect: LS_WORKS_URL,
  },
];

export const headerButtons: menuItem[] = [
  {
    label: 'common.log-in',
    redirect: 'login',
  },
  {
    label: 'common.try-now',
    subMenu: [
      {
        label: 'common.buy-now',
        redirect: 'shop',
      },
      {
        label: 'common.demo',
        redirect: 'demo',
      },
      {
        label: 'common.log-in',
        redirect: LS_WORKS_URL,
      },
    ],
  },
];
