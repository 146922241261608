import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { NavigateAction, SelectMenuItemsState, SelectSideMenuState } from '@lsworks/data';
import { ArrayUtil, ScrollUtil } from '@lsworks/tools';
import { SideMenuToggle } from '../../components/layout/+store/menu.actions';
import { USCODE } from 'apps/lsworks/src/app/shared/services/country-handler-service/country-handler-constants';
import { NEURALIGN_ACADEMY_URL, NEURALIGN_USA_URL } from 'apps/lsworks/src/app/app.constants';
import { CountryHandlerService } from 'apps/lsworks/src/app/shared/services/country-handler-service/country-handler.service';

@Injectable()
export class MenuUtil {
  constructor(public store: Store<any>, private router: Router, private countryHandler: CountryHandlerService) {}

  loadMenuState() {
    return this.store.select(SelectMenuItemsState).pipe(map(this.mapMenu));
  }

  mapMenu(res) {
    const menuList = ArrayUtil.objValues(res.items);
    if (menuList.length) menuList.pop();
    return menuList;
  }

  loadEffectMenu(sideNav) {
    this.store.select(SelectSideMenuState).subscribe((item) => {
      this.doMenu(sideNav, item);
    });
  }

  blockScroll(res) {
    const docClass = document.querySelector('ui-layout').classList;

    if (res === 'open') {
      docClass.add('block-scroll');
    } else {
      docClass.remove('block-scroll');
    }
  }

  showHide(res) {
    const sideMenuNav = document.querySelector('mat-sidenav').classList;
    if (res === 'show') {
      sideMenuNav.add('showing');
    } else {
      sideMenuNav.remove('showing');
    }
  }

  clickPage(item) {
    if (item.includes('https')) {
      const orgUrl = this.countryHandler.userCountry === USCODE ? NEURALIGN_USA_URL : NEURALIGN_ACADEMY_URL;
      window.open(orgUrl, '_blank');
      return;
    }
    if (item.substring(0, 1) !== '#') {
      this.store.dispatch(NavigateAction({ route: item }));
      return;
    }

    ScrollUtil.smoothScroll(item);
  }

  clickPageToggle(sideNav, item) {
    this.toggleMenu(sideNav, () => {
      this.clickPage(item);
    });
  }

  doMenu(sideNav, state) {
    if (!sideNav) return;

    if (state.opened) {
      this.blockScroll('open');
      this.showHide('show');
      sideNav.open();
    } else {
      this.blockScroll('close');
      this.showHide('hide');
      sideNav.close();
    }
  }

  toggleMenu(sideNav, act = null) {
    if (!sideNav) return;

    sideNav.toggle().then((res) => {
      this.blockScroll(res);
      this.store.dispatch(SideMenuToggle());

      if (act != null) {
        act();
      }
    });
  }
}
