import { Injectable } from '@angular/core';
import { cloneDeepWith } from 'lodash';
import { countryTransforms, transformText } from './contry-transform.consts';
import { getNavLocale } from '@lsworks/data';
import { TranslateService } from '@ngx-translate/core';

interface TransformList {
  text: string;
  replacement: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountryTextTransformService {
  public registeredTransformers = [];

  constructor(private translate: TranslateService) {}

  public applyTransform(userCountry: string) {
    const language = getNavLocale(navigator.language);
    this.translate
      .getTranslation(language)
      .pipe()
      .subscribe((data) => {
        this.translate.setTranslation(language, this.transform(data, userCountry));
      });
  }

  transform(translations: unknown, country: string): unknown {
    if (!translations) {
      return '';
    }

    const transformation = countryTransforms[country];

    if (!transformation) {
      return translations;
    }

    const transformList = transformation.transforms;

    return cloneDeepWith(translations, (value) => {
      if (typeof value === 'string') {
        return this.transformString(value, transformList);
      }
    });
  }

  private transformString(text: string, transformList: TransformList[]): string {
    const lowerText = text.toLowerCase();

    return transformText(transformList, lowerText, text);
  }
}
