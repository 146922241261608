import { Component } from '@angular/core';
import { siteMap } from '../constants/site-map.constants';
import { includes } from 'lodash';
import { Router } from '@angular/router';
import { CountryHandlerService } from '../../../../../../../apps/lsworks/src/app/shared/services/country-handler-service/country-handler.service';
import { USCODE } from 'apps/lsworks/src/app/shared/services/country-handler-service/country-handler-constants';
import { ApiService } from 'apps/lsworks/src/app/shared/services/api/api.service';
import { UserService } from 'apps/lsworks/src/app/shared/services/user/user.service';
@Component({
  selector: 'ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  userCountry: string;

  constructor(
    private countryHandler: CountryHandlerService,
    private router: Router,
    private _api: ApiService,
    private _userService: UserService,
  ) {
    this.userCountry = this.countryHandler.userCountry;
  }
  siteMap = siteMap;

  sendForm(myForm: HTMLFormElement) {
    if (myForm['EMAIL'].value !== '') {
      const formData = {
        email: myForm['EMAIL'].value,
        country: this.countryHandler.userCountry,
        source: 'StayConnected',
      };

      this._api.post(`/mailchimp/addMember`, formData).subscribe();

      this._userService.setUser({
        email: formData.email,
        country: formData.country,
        leadSource: 'footer-newsletter',
      });

      myForm.submit();
      myForm['EMAIL'].value = '';
    }
  }

  public showCanAdvanceLogo() {
    if (this.countryHandler.userCountry === USCODE) {
      return true;
    } else {
      return false;
    }
  }

  public goto(route, fragment?, isOutsideLink?) {
    if (isOutsideLink) {
      window.open(route, '_blank');
    } else if (fragment) {
      this.router.navigate([route], { fragment });
    } else {
      this.router.navigate([route]);
    }
  }

  public drawBorderLine(index) {
    const windowWidth = window.innerWidth;

    let columnToBreak = 4;

    if (windowWidth <= 1152 && windowWidth > 912) {
      columnToBreak = 3;
    } else if (windowWidth <= 912 && windowWidth > 672) {
      columnToBreak = 2;
    } else if (windowWidth <= 672) {
      return false;
    }

    const siteMapLength = this.siteMap.length;
    const lastFour = [];
    for (let i = 0; i < siteMapLength; i++) {
      if ((i + 1) % columnToBreak === 0) {
        lastFour.push(i);
      }
    }

    if (includes(lastFour, index)) {
      return false;
    } else {
      return true;
    }
  }
}
