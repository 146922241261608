import { escapeRegExp, startCase, toLower, toUpper } from 'lodash';

export const preserveCase = (original: string, replacement: string): string => {
  if (toUpper(original) === original) {
    return toUpper(replacement);
  }
  if (startCase(toLower(original)) === original) {
    return startCase(toLower(replacement));
  }
  return toLower(replacement);
};

export const transformText = (transformList, lowerText, originalText) => {
  for (const transform of transformList) {
    if (lowerText.includes(transform.text)) {
      const regex = new RegExp(`\\b${escapeRegExp(transform.text)}\\b`, 'gi');
      return originalText.replace(regex, (match) => preserveCase(match, transform.replacement));
    }
  }
  return originalText;
};

export const countryTransforms = {
  US: {
    transforms: [{ text: 'cognitive therapy', replacement: 'cognitive development' }],
  },
};
