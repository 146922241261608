import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CDHService } from '../../services/cdh/cdh.service';

@Injectable({
  providedIn: 'root',
})
export class IsChristmasTimeGuard implements CanActivate {
  constructor(private _cdh: CDHService) {}
  canActivate(): boolean {
    return true;
    // TEMPORARY ENABLE
    // return this._cdh.isActive;
  }
}
