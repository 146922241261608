import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { MenuItems } from '@lsworks/ui';
import { MenuData } from '@lsworks/data';
import { InternationalizationManagerService } from '@lsworks/tools';
import { CountryHandlerService } from './shared/services/country-handler-service/country-handler.service';

import { MatDialog } from '@angular/material/dialog';
import { CookiesConsentDialog } from 'libs/ui/src/lib/dialogs/cookies/cookies-consent/cookies-consent.dialog';
import { CookiesService } from 'libs/ui/src/lib/services/menu/cookies.service';
import { Subscription } from 'rxjs';

declare let gtag: Function;

@Component({
  selector: 'lsw-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title: any;
  cookiesDialogSub: Subscription;

  constructor(
    private translate: InternationalizationManagerService,
    private store: Store<any>,
    private router: Router,
    private countryHandler: CountryHandlerService,
    private _dialog: MatDialog,
    private _cookies: CookiesService,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'UA-180544213-1', { page_path: event.urlAfterRedirects });
      }
    });
  }

  async ngOnInit() {
    await this.countryHandler.initCountry();
    this.countryHandler.setCustomTranslation();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }

      window.scrollTo(0, 0);
    });

    this.store.dispatch(MenuItems(MenuData.GetMenu()));

    this.cookiesDialogSub = this._cookies.showCookiesDialog$.subscribe((show) => {
      if (show) this._showCookiesDialog();
    });
  }

  ngOnDestroy(): void {
    this.cookiesDialogSub.unsubscribe();
  }

  private _showCookiesDialog() {
    this._dialog.open(CookiesConsentDialog, {
      hasBackdrop: false,
      width: '480px',
      autoFocus: 'autofocus',
      position: {
        bottom: '24px',
        left: '24px',
      },
    });
  }
}
